import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { ClimbingBoxLoader } from 'react-spinners'

import { ThemeProvider } from '@material-ui/styles'

import MuiTheme from './theme'

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints'

// Pages

import PageError404 from './example-pages/PageError404'
import PageError500 from './example-pages/PageError500'
import PageError505 from './example-pages/PageError505'
const Login = lazy(() => import('./pages/auth/Login'))
const Company = lazy(() => import('./pages/auth/Company'))
const Register = lazy(() => import('./pages/auth/Register'))
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'))
const ChangePassword = lazy(() => import('./pages/auth/ChangePassword'))
// const RecoverPassword = lazy(() => import('./pages/auth/RecoverPassword'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard1'))
const Estimate = lazy(() => import('./pages/sales/Estimate'))
const Logout = lazy(() => import('./pages/auth/Logout'))

const Routes = () => {
  const location = useLocation()

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  }

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  }

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false)
    // useEffect(() => {
    //   let timeout = setTimeout(() => setShow(true), 300);
    //   return () => {
    //     clearTimeout(timeout);
    //   };
    // }, []);
    return <></>
    let ret = (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
                <div className='d-flex align-items-center flex-column px-4'>
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className='text-muted font-size-xl text-center pt-3'>
                  Please wait while we load the live preview examples
                  <span className='font-size-lg d-block text-dark'>
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from='/' to='/login' />
            {/* <Route path={['/Overview']}>
                <PresentationLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/Overview" component={Overview} />
                    </motion.div>
                  </Switch>
                </PresentationLayout>
              </Route> */}
            <Route
              path={[
                '/dashboard',
                '/dental-offices',
                '/add-dentalOffices',
                '/dental-offices-settings',
                '/roles',
                '/users',
                '/add-dentaloffice',
                '/edit-dentaloffice',
                '/view-dentaloffice',
                '/call-centers',
                '/add-callcenter',
                '/edit-callcenter',
                '/view-callcenter',
                '/call-centers-settings',
                '/doctors',
                '/add-doctor',
                '/edit-doctor',
                '/view-doctor',
                '/patients',
                '/all-patients',
                '/add-patient',
                '/edit-patient',
                '/view-patient',
                '/locations',
                '/add-location',
                '/edit-location',
                '/view-location',
                '/reports',
                '/firewall',
                '/two-factor-auth',
                '/settings',
                '/change-password'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/dashboard' component={Dashboard} />
                    <Route
                      path='/dental-offices'
                      component={lazy(() =>
                        import('./pages/dentalOffices/dentalOffices')
                      )}
                    />
                    <Route
                      path='/add-dentaloffice'
                      component={lazy(() =>
                        import('./pages/dentalOffices/AddDentalOffice')
                      )}
                    />
                    <Route
                      path='/edit-dentaloffice/:id'
                      component={lazy(() =>
                        import('./pages/dentalOffices/EditDentalOffice')
                      )}
                    />
                    <Route
                      path='/view-dentaloffice/:id'
                      component={lazy(() =>
                        import('./pages/dentalOffices/ViewDentalOffice')
                      )}
                    />
                    <Route
                      path='/dental-offices-settings'
                      component={lazy(() =>
                        import('./pages/dentalOffices/DentalSettings')
                      )}
                      exact
                    />
                    <Route
                      path='/dental-offices-settings/design-form/:id'
                      component={lazy(() =>
                        import('./pages/settings/DesignForm')
                      )}
                      exact
                    />
                    <Route
                      path='/call-centers'
                      component={lazy(() =>
                        import('./pages/call-centers/CallCenters')
                      )}
                    />
                    <Route
                      path='/add-callcenter'
                      component={lazy(() =>
                        import('./pages/call-centers/AddCallCenter')
                      )}
                    />
                    <Route
                      path='/edit-callcenter/:id'
                      component={lazy(() =>
                        import('./pages/call-centers/EditCallCenter')
                      )}
                    />
                    <Route
                      path='/view-callcenter/:id'
                      component={lazy(() =>
                        import('./pages/call-centers/ViewCallCenter')
                      )}
                    />
                    <Route
                      path='/call-centers-settings'
                      component={lazy(() =>
                        import('./pages/call-centers/CallCentersSettings')
                      )}
                    />
                    <Route
                      path='/doctors'
                      component={lazy(() => import('./pages/doctors/Doctors'))}
                    />
                    <Route
                      path='/add-doctor'
                      component={lazy(() =>
                        import('./pages/doctors/AddDoctor')
                      )}
                    />
                    <Route
                      path='/edit-doctor/:id'
                      component={lazy(() =>
                        import('./pages/doctors/EditDoctor')
                      )}
                    />
                    <Route
                      path='/view-doctor/:id'
                      component={lazy(() =>
                        import('./pages/doctors/ViewDoctor')
                      )}
                    />
                    <Route
                      path='/all-patients'
                      component={lazy(() =>
                        import('./pages/patients/Patients')
                      )}
                      exact
                    />
                    <Route
                      path='/patients/:id'
                      component={lazy(() =>
                        import('./pages/patients/Patients')
                      )}
                      exact
                    />
                    <Route
                      path='/add-patient'
                      component={lazy(() =>
                        import('./pages/patients/AddPatient')
                      )}
                    />
                    <Route
                      path='/edit-patient/:id'
                      component={lazy(() =>
                        import('./pages/patients/EditPatient')
                      )}
                    />
                    <Route
                      path='/view-patient/:id'
                      component={lazy(() =>
                        import('./pages/patients/ViewPatient')
                      )}
                    />
                    <Route
                      path='/locations'
                      component={lazy(() =>
                        import('./pages/locations/Locations')
                      )}
                    />
                    <Route
                      path='/add-location'
                      component={lazy(() =>
                        import('./pages/locations/AddLocation')
                      )}
                    />
                    <Route
                      path='/edit-location/:id'
                      component={lazy(() =>
                        import('./pages/locations/EditLocation')
                      )}
                    />
                    <Route
                      path='/view-location/:id'
                      component={lazy(() =>
                        import('./pages/locations/ViewLocation')
                      )}
                    />
                    <Route
                      path='/reports'
                      component={lazy(() =>
                        import('./pages/reports/DentalOffice')
                      )}
                      exact
                    />
                    <Route
                      path='/users'
                      component={lazy(() => import('./pages/users/users'))}
                      exact
                    />
                    <Route
                      path='/users/add'
                      component={lazy(() => import('./pages/users/addUsers'))}
                      exact
                    />
                    <Route
                      path='/users/edit/:id'
                      component={lazy(() => import('./pages/users/editUsers'))}
                      exact
                    />
                    <Route
                      path='/users/view/:id'
                      component={lazy(() => import('./pages/users/ViewUsers'))}
                      exact
                    />
                    <Route
                      path='/change-password'
                      component={lazy(() =>
                        import('./pages/settings/ChangePassword')
                      )}
                      exact
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route path={[]}>
              <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}></motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route>

            <Route
              path={[
                '/login',
                '/select-company',
                '/signup',
                '/forgot-password',
                '/forgot-password-2',
                // '/change-password',
                '/verify-otp',
                '/PageError404',
                '/PageError500',
                '/PageError505',
                '/logout'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/login' component={Login} />
                    <Route path='/select-company' component={Company} />
                    <Route path='/signup' component={Register} />
                    <Route path='/forgot-password' component={ForgotPassword} />
                    <Route
                      path='/forgot-password-2'
                      component={ChangePassword}
                    />
                    <Route
                      path='/verify-otp'
                      component={lazy(() =>
                        import('./pages/auth/OtpVerification')
                      )}
                      exact
                    />
                    {/* <Route path="/change-password" component={ChangePassword} /> */}
                    <Route path='/PageError404' component={PageError404} />
                    <Route path='/PageError500' component={PageError500} />
                    <Route path='/PageError505' component={PageError505} />
                    <Route path='/logout' component={Logout} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
